<template>
  <div class="map">
    <div class="map-box" ref="map"></div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      map: null,
      editor: null,
      myKey: "",
    };
  },
  mounted() {
    this.initMap();
  },
  computed: {},
  methods: {
    initMap() {
      //定义地图中心点坐标
      const center = new TMap.LatLng(39.98412, 116.307484);
      //定义map变量，调用 TMap.Map() 构造函数创建地图
      this.map = new TMap.Map(this.$refs.map, {
        center: center, //设置地图中心点坐标
        zoom: 13, //设置地图缩放级别
      });
      console.log(TMap);
      console.log(window);
      // 初始化几何图形及编辑器
      this.editor = new TMap.tools.GeometryEditor({
        map: this.map, // 编辑器绑定的地图对象
        overlayList: [
          // 可编辑图层
          {
            //GeometryEditor 以 MultiPolygon（可以理解为多边形图层）激活进行编辑
            id: "polygon133",
            overlay: new TMap.MultiPolygon({
              map: this.map,
            }),
          },
        ],
        actionMode: TMap.tools.constants.EDITOR_ACTION.DRAW, //编辑器的工作模式
        snappable: true, // 开启邻近吸附
      });
      //   监听绘制结束事件，获取绘制几何图形
      this.editor.on("draw_complete", (geometry) => {
        console.log(geometry);
      });
    },
    drawing_polygon() {
      this.editor.setActiveOverlay("polygon133");
      //参数中的"polygon133"即为要进行编辑的MultiPolygon的id（注意方代码有定义）
    },
  },
};
</script>
<style lang="less" scoped></style>
