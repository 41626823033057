<template>
  <div>
    <div v-if="isShow == 0">
      <bread-crumb :crumbData="['地图围栏']" :chooseCrumbData="1"></bread-crumb>
      <search-case
        :searchData="searchData"
        @inquireBtnClick="inquireBtnClick"
      ></search-case>

      <div
        style="
          padding: 10px 20px;
          border-bottom: 1px solid #eee;
          display: flex;
          align-items: center;
        "
      >
        <div style="margin-right: 5px">
          <el-button type="primary" size="mini" @click="isShow = 1">
            <div style="display: flex; align-items: center; font-size: 14px">
              <img
                style="width: 16px; height: 16px"
                :src="require('@/assets/iconImg/logo.png')"
              />
              <span style="margin-left: 3px">新增围栏</span>
            </div>
          </el-button>
        </div>
        <div style="margin-right: 5px">
          <el-button type="danger" size="mini" @click="batchDelete">
            <div style="display: flex; align-items: center; font-size: 14px">
              <img
                style="width: 16px; height: 16px"
                :src="require('@/assets/iconImg/logo.png')"
              />
              <span style="margin-left: 3px">批量删除</span>
            </div>
          </el-button>
        </div>
        <div style="margin-right: 5px">
          <el-button type="danger" size="mini">
            <div style="display: flex; align-items: center; font-size: 14px">
              <img
                style="width: 16px; height: 16px"
                :src="require('@/assets/iconImg/logo.png')"
              />
              <span style="margin-left: 3px">全景图</span>
            </div>
          </el-button>
        </div>
      </div>

      <div style="padding: 20px">
        <el-table
          ref="multipleTable"
          :data="tableData"
          tooltip-effect="dark"
          border
          @selection-change="handleSelectionChange"
          style="width: 100%"
        >
          <el-table-column type="selection" width="44"> </el-table-column>
          <el-table-column
            prop="create_time"
            sortable
            label="创建日期"
          ></el-table-column>
          <el-table-column prop="shopkeeper" label="店主"></el-table-column>
          <el-table-column prop="fence_name" label="名称"></el-table-column>
          <el-table-column prop="store" label="门店"></el-table-column>
          <el-table-column prop="region" label="所在地"></el-table-column>
          <el-table-column prop="location" label="地址"></el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <i class="el-icon-edit poiner" @click="handleEdit(scope.row)"></i>
              <span style="margin: 0 10px"></span>
              <i
                class="el-icon-delete poiner"
                @click="handleDelete(scope.row)"
              ></i>
            </template>
          </el-table-column>
        </el-table>
        <div class="tableBottom">
          <span style=""
            >显示第&nbsp;{{
              tableData.length == 0 ? 0 : (page - 1) * length + 1
            }}&nbsp;至&nbsp;{{
              (page - 1) * length + tableData.length
            }}&nbsp;项结果，共&nbsp;{{ tableFinshNum }}&nbsp;项</span
          >

          <div
            style="
              display: flex;
              justify-content: flex-end;
              align-items: center;
            "
          >
            <el-button>首页</el-button>
            <el-button>上页</el-button>
            <el-pagination
              background
              layout=" pager,slot"
              :total="tableFinshNum"
            ></el-pagination>
            <el-button>下页</el-button>
            <el-button>末页</el-button>
          </div>
        </div>
      </div>
    </div>
    <AddMap v-if="isShow == 1" />
  </div>
</template>
<script>
import AddMap from "./addMap/addMap.vue";
import { getTime } from "../../../../until/getTime";
export default {
  components: { AddMap },
  data() {
    return {
      isShow: 0,
      searchData: {
        date: "",
        QueryContent: "",
        inquirePlaceholder: "输入要查询的内容",
        isInquireValue: true,
        isInquireBtn: true,
      },

      tableData: [],
      multipleSelection: [],
      tableFinshNum: 0,
      page: 1,
      length: 10,
      commercial_id: "",
      text: {
        formattedAddress: "天安门广场",
        longitude: 116.39775500000002,
        latitude: 39.903179,
      },
    };
  },
  mounted() {
    this.commercial_id =
      localStorage.getItem("commercial_id") ||
      sessionStorage.getItem("commercial_id");
    this.getTabList();
  },
  computed: {},
  methods: {
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    getTabList() {
      this.$http
        .post("/system/map_fence/lst", {
          currentPage: this.page,
          currentLength: this.length,
          commercial_id: this.commercial_id,
        })
        .then((res) => {
          console.log(res);
          this.tableData = res.data.data;
          this.tableFinshNum = res.data.count;
        });
    },
    handleEdit() {},
    inquireBtnClick(data) {
      this.$http
        .post("/system/map_fence/search", {
          firstTime: getTime(data.dateRange[0]),
          lastTime: getTime(data.dateRange[1]),
          search: data.inquireValue,
          commercial_id: this.commercial_id,
        })
        .then((res) => {
          this.tableData = res.data.data;
          this.tableFinshNum = res.data.count;
        });
    },
    batchDelete() {
      if (this.multipleSelection.length == 0) return;
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          const idArr = this.multipleSelection.map((item) => {
            return item.id;
          });
          this.$http
            .post("/system/map_fence/bacthDel", {
              idArr,
            })
            .then((res) => {
              if (res.data.status == "success") {
                this.$message({
                  type: "success",
                  message: res.data.msg,
                });
                this.getTabList();
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    handleDelete(row) {
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$http
            .post("/system/map_fence/del", {
              id: row.id,
            })
            .then((res) => {
              if (res.data.status == "success") {
                this.$message({
                  type: "success",
                  message: res.data.msg,
                });
                this.getTabList();
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
};
</script>
<style scoped lang="less">
.tableBottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #666;
  font-size: 14px;
}
/deep/ .cell {
  padding: 0 !important;
}
</style>
